import React from 'react'
import { graphql } from 'gatsby'
import { Box, Container, Divider, useMediaQuery, useTheme } from '@mui/material'
import { Formik, Form, FormikHelpers } from 'formik'
import { object, string, array, mixed } from 'yup'

import cvFormats from '_core/constants/cv-formats'
import { useLoading } from '_core/context/LoadingContext'
import Section from '_core/components/atoms/layout/Section'
import Button from '_core/components/atoms/buttons/Button'
import FormikTextField from '_core/components/atoms/inputs/formik/FormikTextField'
import FormikFileUpload from '_core/components/atoms/inputs/formik/FormikFileUpload'
import FormikCheckboxGroup from '_core/components/atoms/inputs/formik/FormikCheckboxGroup'
import { CheckboxDataArrayItemProps } from '_core/components/atoms/inputs/Checkbox'

import { useMessage } from '_core/context/MessageContext'

import encodeForm from 'public/helpers/encodeForm'
import Layout from 'public/components/organisms/global/Layout'
import PageTitleHero from 'public/components/organisms/hero/PageTitleHero'
import TwoColContentLayout from 'public/components/organisms/layout/TwoColContentLayout'
import TeamMemberContactCard from 'public/components/molecules/cards/teamMember/TeamMemberContactCard'
import Text from 'public/components/molecules/contentComponents/Text'
import SectionHeading from 'public/components/molecules/sections/SectionHeading'

interface Props {
  data: Queries.VacancyDetailQuery
}

interface FormValues {
  vacancy: string
  firstName: string
  lastName: string
  email: string
  telephoneNumber: string
  cv: HTMLInputElement | undefined
  availabilityInput: CheckboxDataArrayItemProps[]
  availability: string
}

const dividerSpacing = { xs: 4, sm: 6, lg: 8 }
const divider = <Divider sx={{ my: { ...dividerSpacing } }} />

const VacancyDetail = ({ data }: Props) => {
  const { dispatch: loadingDispatch } = useLoading()
  const { dispatch: messageDispatch } = useMessage()
  const theme = useTheme()
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'))
  const vacancy = data.datoCmsVacancy
  const landing = data.datoCmsLandingPage
  const pageTitle = `${vacancy.title}, ${vacancy.location}`

  const leftCol = (
    <>
      <Box component="h2" typography="h4">
        What We Offer
      </Box>
      <Text bullets text={vacancy.whatWeOfferNode.childMarkdownRemark.html} />
      {divider}
      <Box component="h2" typography="h4">
        Requirements
      </Box>
      <Text bullets text={vacancy.requirementsNode.childMarkdownRemark.html} />
      {divider}
      <Box component="h2" typography="h4">
        Responsibilities
      </Box>
      <Text
        mb={{ xs: 4, sm: 6, lg: 8, xl: 0 }}
        bullets
        text={vacancy.responsibilitiesNode.childMarkdownRemark.html}
      />

      {!lgUp && (
        <>
          <Divider sx={{ mb: 4 }} />
          <Box component="h2" typography="h4" mb={2} lineHeight="1">
            Hiring Manager
          </Box>
          {vacancy && vacancy.hiringManager && (
            <TeamMemberContactCard member={vacancy.hiringManager} />
          )}
        </>
      )}
    </>
  )
  const rightCol = (
    <>
      {lgUp && (
        <>
          <Box component="h2" typography="h4" mb={2} lineHeight="1">
            Hiring Manager
          </Box>
          <Divider sx={{ mb: 3 }} />
          <TeamMemberContactCard member={vacancy.hiringManager} />
        </>
      )}
    </>
  )

  const handleSubmit = (
    values: FormValues,
    actions: FormikHelpers<FormValues>
  ) => {
    loadingDispatch({
      type: 'START_LOADING',
    })
    if (values.availabilityInput.length > 0) {
      values.availability = values.availabilityInput.join()
    }

    fetch('/?no-cache=1', {
      method: 'POST',
      body: encodeForm({ 'form-name': 'vacancy-application', ...values }),
    })
      .then(() => {
        messageDispatch({
          type: 'SET_MESSAGE',
          payload: {
            message:
              'We have received your vancancy application and will be in touch soon.',
            status: 'success',
          },
        })
        loadingDispatch({
          type: 'STOP_LOADING',
        })
        actions.resetForm()
      })
      .catch(() => {
        messageDispatch({
          type: 'SET_MESSAGE',
          payload: {
            message:
              'There was an error when trying to submit your application. Please try again or get in contact.',
            status: 'error',
          },
        })
        loadingDispatch({
          type: 'STOP_LOADING',
        })
      })
      .finally(() => actions.setSubmitting(false))
  }
  return (
    <Layout
      metadata={{
        seoTags: vacancy.seoMetaTags,
        customDescription: vacancy.requirements.substring(0, 200),
        customImage: {
          src: landing.metaImage.gatsbyImageData.images.fallback.src,
          width: 1200,
          height: 630,
        },
      }}
    >
      <PageTitleHero
        mainHeading={pageTitle}
        smallHeading="Careers"
        imageLandscape={landing.imageLandscape}
        imagePortrait={landing.imagePortrait}
        twoColContentLayout
      />
      <TwoColContentLayout
        leftCol={leftCol}
        rightCol={rightCol}
        shareButtonsProps={{
          url: `careers/${vacancy.slug}`,
          contentType: 'Vacancy',
          description: pageTitle,
          source: 'detail-page',
        }}
      />
      <Section bgcolor="lightGrey" vSpace>
        <SectionHeading heading="Apply for this position" />

        <Container maxWidth="md">
          <Formik
            initialValues={{
              vacancy: pageTitle,
              firstName: '',
              lastName: '',
              email: '',
              telephoneNumber: '',
              cv: undefined,
              availabilityInput: [],
              availability: '',
            }}
            validationSchema={object().shape({
              firstName: string().required('Please enter your first name'),
              lastName: string().required('Please enter your last name'),
              email: string()
                .email('Please supply a valid email address')
                .required('Email address is Required'),
              telephoneNumber: string().required(
                'Please enter your telephone number'
              ),
              availabilityInput: array().min(
                1,
                'At least one checkbox is required'
              ),
              cv: mixed()
                .required('Please upload your CV file')
                .test(
                  'fileFormat',
                  'Your CV must be a either pdf or word file.',
                  (value) => value && cvFormats.includes(value.type)
                )
                .test(
                  'fileSize',
                  'Your CV file is too large. Please upload a versions that is less than 8mb in size.',
                  (value) => value && value.size <= 8388608 //8mb
                ),
            })}
            onSubmit={(
              values: FormValues,
              actions: FormikHelpers<FormValues>
            ) => {
              handleSubmit(values, actions)
            }}
          >
            <Form name="vacancy-application" data-netlify={true}>
              <FormikTextField name="vacancy" type="hidden" />
              <FormikTextField name="availability" type="hidden" />
              <FormikTextField
                name="firstName"
                placeholder="First Name"
                label="First Name"
                fullWidth
                caps
              />
              <FormikTextField
                name="lastName"
                placeholder="Last Name"
                label="Last Name"
                fullWidth
                caps
                sx={{
                  mt: 2,
                }}
              />
              <FormikTextField
                name="email"
                placeholder="Email Address"
                label="Email Address"
                fullWidth
                type="email"
                caps
                sx={{
                  mt: 2,
                }}
              />
              <FormikTextField
                name="telephoneNumber"
                placeholder="Telephone Number"
                label="Telephone Number"
                fullWidth
                caps
                sx={{
                  mt: 2,
                  mb: 3,
                }}
              />
              <FormikFileUpload
                name="cv"
                id="cvUpload"
                accept=".pdf, .doc, .docx"
                label="Upload CV"
                helperText="Your CV must be a either pdf or word file."
              />
              <Box mt={3} mb={4}>
                <FormikCheckboxGroup
                  name="availabilityInput"
                  groupLabel="Availability"
                  checkboxDataArray={[
                    { value: 'full-time', label: 'Full time' },
                    { value: 'part-time', label: 'Part time' },
                  ]}
                />
              </Box>

              <Button
                variant="contained"
                color="primary"
                type="submit"
                sizeVariant="xxl"
              >
                Apply Now
              </Button>
            </Form>
          </Formik>
        </Container>
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query VacancyDetail($slug: String!) {
    datoCmsLandingPage(originalId: { eq: "1462830" }) {
      ...LandingPageTitleHero
      metaImage: heroBackgroundImage {
        gatsbyImageData(
          layout: FIXED
          imgixParams: { w: "1200", h: "630", fit: "crop", crop: "focalpoint" }
        )
      }
    }
    datoCmsVacancy(slug: { eq: $slug }) {
      ...Vacancy
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`

export default VacancyDetail
