import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { styled } from '@mui/material'
import { LocalPostOffice } from '@mui/icons-material'
import { FaFacebookF, FaTwitter, FaLinkedinIn } from 'react-icons/fa'

import { ButtonProps } from '_core/components/atoms/buttons/Button'
import ExternalButtonLink from '_core/components/atoms/buttons/ExternalButtonLink'

interface ButtonClickProps {
  shareTo: 'Email' | 'Facebook' | 'Twitter' | 'LinkedIn'
  source: 'card' | 'detail-page'
  description: string
  contentType: string
}

export interface ShareButtonsProps extends Omit<ButtonClickProps, 'shareTo'> {
  url: string
  hashtags?: string
  buttonProps?: Omit<ButtonProps, 'children'>
}

const StyledExternalButtonLink = styled(ExternalButtonLink)(({ theme }) => ({
  margin: '0 3px',
}))

const ShareButtons = ({
  url,
  description,
  hashtags,
  contentType,
  source,
  buttonProps,
}: ShareButtonsProps) => {
  const handleClick = ({
    shareTo,
    contentType,
    source,
    description,
  }: ButtonClickProps) => {
    if (typeof window !== 'undefined') {
      window.gtag('event', `${contentType}_share`, {
        action: `Share to ${shareTo} from ${contentType} ${source}.`,
        description: description,
      })
    }
  }

  const styledButtonProps = {
    ...buttonProps,
    sizeVariant: buttonProps?.sizeVariant ? buttonProps?.sizeVariant : 'xl',
    colorVariant: 'outline-dark' as const,
    variant: 'outlined' as const,
    round: true,
  }

  return (
    <StaticQuery<Queries.ShareButtonsQuery>
      query={graphql`
        query ShareButtons {
          site {
            siteMetadata {
              siteUrl
            }
          }
        }
      `}
      render={(data) => {
        const shareUrl = `${data?.site?.siteMetadata?.siteUrl}/${url}`

        return (
          <>
            <StyledExternalButtonLink
              href={`mailto:?subject=${description}&body=${shareUrl}`}
              buttonProps={{
                ...styledButtonProps,
                ['aria-label']: 'Share via Email',
              }}
              onClick={(e) =>
                handleClick({
                  shareTo: 'Email',
                  contentType: contentType,
                  source: source,
                  description: description,
                })
              }
            >
              <LocalPostOffice className="icon" fontSize="inherit" />
            </StyledExternalButtonLink>
            <StyledExternalButtonLink
              href={`https://www.linkedin.com/shareArticle?mini=true&url=${shareUrl}&title=${description}&summary=${description}`}
              buttonProps={{
                ...styledButtonProps,
                ['aria-label']: 'Share via LinkedIn',
              }}
              onClick={(e) =>
                handleClick({
                  shareTo: 'LinkedIn',
                  contentType: contentType,
                  source: source,
                  description: description,
                })
              }
            >
              <FaLinkedinIn />
            </StyledExternalButtonLink>
            <StyledExternalButtonLink
              href={`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`}
              buttonProps={{
                ...styledButtonProps,
                ['aria-label']: 'Share via Facebook',
              }}
              onClick={(e) =>
                handleClick({
                  shareTo: 'Facebook',
                  contentType: contentType,
                  source: source,
                  description: description,
                })
              }
            >
              <FaFacebookF />
            </StyledExternalButtonLink>
            <StyledExternalButtonLink
              href={`https://twitter.com/intent/tweet?url=${shareUrl}&text=${description}&via=Delta_Exec${
                hashtags ? `&hashtags=${hashtags}` : ''
              }`}
              buttonProps={{
                ...styledButtonProps,
                ['aria-label']: 'Share via Twitter',
              }}
              onClick={(e) =>
                handleClick({
                  shareTo: 'Twitter',
                  contentType: contentType,
                  source: source,
                  description: description,
                })
              }
            >
              <FaTwitter />
            </StyledExternalButtonLink>
          </>
        )
      }}
    />
  )
}

export default ShareButtons
