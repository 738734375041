import React, { memo } from 'react'
import { FormControl, FormGroup } from '@mui/material'
import { FieldArray, useFormikContext } from 'formik'

import FormLabel from '_core/components/atoms/inputs/FormLabel'
import Checkbox, {
  CheckboxDataArrayItemProps,
} from '_core/components/atoms/inputs/Checkbox'
import ErrorMessage from '_core/components/atoms/inputs/ErrorMessage'

interface Props {
  name: string
  groupLabel: string
  checkboxDataArray: CheckboxDataArrayItemProps[]
}

const FormikCheckboxGroup = ({
  name,
  groupLabel,
  checkboxDataArray,
}: Props) => {
  const { values, getFieldMeta } = useFormikContext()

  return (
    <>
      <FieldArray
        name={name}
        render={(arrayHelpers) => {
          const meta = getFieldMeta(name)
          const hasError = meta.touched === true && meta.error !== undefined
          const valid = meta.touched && !meta.error

          return (
            <>
              <ErrorMessage collapsed={hasError} mb={1}>
                {meta.error}
              </ErrorMessage>

              <FormControl component="fieldset">
                <FormLabel>{groupLabel}</FormLabel>
                <FormGroup row>
                  {checkboxDataArray.map(
                    (checkbox: CheckboxDataArrayItemProps) => {
                      const checked = values[name].includes(checkbox.value)
                      return (
                        <Checkbox
                          key={checkbox.value}
                          label={checkbox.label}
                          smallText
                          checkboxProps={{
                            'aria-checked': checked,
                            error: hasError,
                            onChange: (e) => {
                              if (e.target.checked)
                                arrayHelpers.push(checkbox.value)
                              else {
                                const idx = values[name].indexOf(checkbox.id)
                                arrayHelpers.remove(idx)
                              }
                            },
                          }}
                          checked={checked}
                        />
                      )
                    }
                  )}
                </FormGroup>
              </FormControl>
            </>
          )
        }}
      />
    </>
  )
}

export default memo(FormikCheckboxGroup)
