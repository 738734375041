import React, { memo } from 'react'
import {
  FormLabel as FormLabelBase,
  FormLabelProps as FormLabelBaseProps,
  styled,
} from '@mui/material'

const StyledFormLabel = styled(FormLabelBase)({
  textTransform: 'uppercase',
  ['&.Mui-focused']: {
    color: 'initial',
  },
})

const FormLabel = (props: FormLabelBaseProps) => {
  return <StyledFormLabel component="legend" {...props} />
}

export default memo(FormLabel)
