import React, { memo } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Box, useTheme } from '@mui/material'

import { FaLinkedinIn } from 'react-icons/fa'

import RoundFrame from '_core/components/atoms/media/RoundFrame'
import Flex from '_core/components/atoms/layout/Flex'
import ExternalButtonLink from '_core/components/atoms/buttons/ExternalButtonLink'
import ImageHover from '_core/components/atoms/media/ImageHover'
import InternalLink from '_core/components/atoms/links/InternalLink'

interface Props {
  member: Queries.DatoCmsTeamMember
}

const TeamMemberContactCard = ({ member }: Props) => {
  const theme = useTheme()
  return (
    <Flex alignItems="center">
      <Box width="90px" mr="15px">
        <InternalLink
          slug={member.slug}
          apiKey={member.model.apiKey}
          aria-label={member.name}
        >
          <RoundFrame>
            <ImageHover>
              <GatsbyImage
                image={member.profileImage.gatsbyImageData}
                alt={`Picture of ${member.name}`}
              />
            </ImageHover>
          </RoundFrame>
        </InternalLink>
      </Box>
      <Box>
        <InternalLink slug={member.slug} apiKey={member.model.apiKey}>
          <Box
            typography="h4Sans"
            sx={{
              display: 'block',
              fontWeight: theme.typography.fontWeightBold,
              mb: 0,
            }}
          >
            {member.name}
          </Box>
        </InternalLink>
        {member.jobTitle}

        <Flex mt={1}>
          <ExternalButtonLink
            href={member.linkedinProfileUrl}
            buttonProps={{
              round: true,
              sizeVariant: 'md',
              colorVariant: 'outline-dark',
              variant: 'outlined',
              ['aria-label']: 'Contact {member.name} via LinkedIn',
            }}
          >
            <FaLinkedinIn />
          </ExternalButtonLink>
        </Flex>
      </Box>
    </Flex>
  )
}

export default memo(TeamMemberContactCard)
