type FormDataState = { [key: string]: string }

const encodeForm = (data: FormDataState) => {
  const formData = new FormData()

  for (const key of Object.keys(data)) {
    formData.append(key, data[key])
  }

  return formData
}

export default encodeForm
