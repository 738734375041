import React from 'react'

import { Box, styled } from '@mui/material'

import Flex from '_core/components/atoms/layout/Flex'
import ShareButtons, {
  ShareButtonsProps,
} from 'public/components/molecules/buttons/shareButtons/ShareButtons'

const ShareButtonContainer = styled(Flex)(({ theme }) => ({
  [theme.breakpoints.down('xl')]: {
    borderTop: `1px solid ${theme.palette.outlineDark}`,
    paddingTop: theme.spacing(4),
  },
}))

const ShareLabel = styled(Box)(({ theme }) => ({
  display: 'inline-block',
  textTransform: 'uppercase',
  fontSize: '1.3rem',
  fontWeight: theme.typography.fontWeightBold,

  [theme.breakpoints.up('xl')]: {
    borderTop: `2px solid ${theme.palette.primary.main}`,
  },
}))

const ContentShareButtons = (props: ShareButtonsProps) => {
  const shareButtonsProps = {
    ...props,
    buttonProps: {
      sizeVariant: 'xl' as const,
      sx: { marginBottom: { xl: 1 } },
    },
    source: 'detail page',
  }

  return (
    <ShareButtonContainer flexDirection={{ xl: 'column' }} alignItems="center">
      <ShareLabel p={{ xl: 2 }} mr={{ xs: 1, xl: 0 }}>
        Share
      </ShareLabel>
      <ShareButtons {...shareButtonsProps} />
    </ShareButtonContainer>
  )
}

export default ContentShareButtons
