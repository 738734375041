import React from 'react'
import { Box, Grid, styled } from '@mui/material'
import ContentShareButtons from 'public/components/molecules/buttons/shareButtons/ContentShareButtons'
import { ShareButtonsProps } from 'public/components/molecules/buttons/shareButtons/ShareButtons'

interface Props {
  leftCol: React.ReactNode
  rightCol: React.ReactNode
  shareButtonsProps: Omit<ShareButtonsProps, 'shareTo'>
}

const GridContainer = styled(Grid)(({ theme }) => ({
  marginTop: `-${theme.twoColLayoutExtraHeight}`,
  overflowX: 'hidden',
  [theme.breakpoints.down('xl')]: {
    ['.share-col']: {
      paddingTop: '30px !important',
    },
  },
  [theme.breakpoints.up('xl')]: {
    ['.content-col']: {
      order: 2,
    },
    ['.share-col']: {
      order: 1,
    },
  },
}))

const GridItemShare = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up('xl')]: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}))

const GridItemRight = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    position: 'relative',
    padding: theme.spacing(4),
    marginTop: theme.spacing(4),
    background: theme.palette.lightGrey,
  },
  [theme.breakpoints.up('xl')]: {
    padding: theme.spacing(6),
    marginTop: theme.spacing(6),
  },
}))

const TwoColContentLayout = ({
  leftCol,
  rightCol,
  shareButtonsProps,
}: Props) => {
  return (
    <GridContainer
      container
      spacing={0}
      alignItems="stretch"
      sx={{ backgroundColor: 'textDark' }}
    >
      <Grid item xs={12} lg={8} xl={9}>
        <Box
          bgcolor="common.white"
          position="relative"
          px={{ xs: 2, sm: 6, xl: 10 }}
          py={{ xs: 4, sm: 8, xl: 12 }}
        >
          <Grid container spacing={8}>
            <Grid item xs={12} xl={9} className="content-col">
              {leftCol}
            </Grid>
            <GridItemShare item xs={12} xl={2} className="share-col">
              <ContentShareButtons {...shareButtonsProps} />
            </GridItemShare>
          </Grid>
        </Box>
      </Grid>

      <GridItemRight item lg={4} xl={3}>
        {rightCol}
      </GridItemRight>
    </GridContainer>
  )
}

export default TwoColContentLayout
